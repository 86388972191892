import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import { withStyles } from "@material-ui/core";
import { Container, Paper, Divider, Grid, Backdrop, CircularProgress, Snackbar, Fab } from '@material-ui/core';
import MuiAlert from "@material-ui/lab/Alert";
import CheckIcon from '@material-ui/icons/Check';
import messageType from "../../utils/messageType";
import globalStyles from "../../../styles/globalStyles";
import { getUserEducations, addUserEducation, updateUserEducation, removeUserEducation } from "../../../redux/crud/users.crud";

const styles = theme => ({
    learningSkill: globalStyles.learningSkill,
    paper: globalStyles.paper,
    paperNoBackground: globalStyles.paperNoBackground,
    learningSkillIcon: globalStyles.learningSkillIcon,
    pageTitle: globalStyles.pageTitle,
    divider: globalStyles.divider
})

class Educations extends Component {
    constructor() {
        super();

        this.state = {
            dataList: [],
            selectedData: {
                id: -1,
                schoolName: "",
                startYear: 2010,
                endYear: 2010
            },
            editorTitle: "",
            isLoading: true,
            isEditing: false,
            showDeleteButton: false,
            showMessage: false,
            message: null,
            messageType: null
        }

    }

    componentDidMount() {
        getUserEducations()
            .then(({ data }) => {
                this.setState({
                    dataList: data,
                    isLoading: false
                })
            })
            .catch((err) => {
                console.log(err);
                this.setState({
                    message: err.message,
                    showMessage: true,
                    isLoading: false,
                    messageType: messageType.ERROR
                })
            });
    }

    renderEducations = (dataList) => dataList.map(data => {
        const { classes } = this.props;

        let periodValue = null;
        if (data.startYear && data.endYear) {
            periodValue = data.startYear + " - " + data.endYear
        };

        let title = data.degree + ", " + data.fieldOfStudy;

        return (
            <div key={data.id} className="educations-wrapper">
                <div className="education-details">
                    <div className="education-details-items">
                        <span className="education-school">{data.schoolName}</span>
                    </div>
                    <div className="education-details-items">
                        <span className="label-item-smaller">{title}</span>
                    </div>
                </div>
                <div className="educations-actions">
                </div>
                <Divider className={classes.divider} />
            </div>
        );
    });

    render() {
        const { classes, data } = this.props;
        const anchorOrigin = { vertical: "top", horizontal: "center" };

        return (
            <div>
                <React.Fragment>
                    <Container maxWidth="md">
                        <Paper className={classes.paper}>
                            <div className="paper-wrapper">
                                <div className="page-title">
                                    <h3 className={classes.pageTitle}>Educations</h3>
                                </div>
                                <div className="top-right">

                                </div>
                                {this.renderEducations(data)}
                            </div>
                        </Paper>
                    </Container>
                </React.Fragment>
            </div>
        );
    }
}

export default withStyles(styles, { withTheme: true })(Educations)
