/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import { checkIsActive } from "../../../../_helpers";
/* import { toAbsoluteUrl, checkIsActive } from "../../../../_helpers"; */

export function HeaderMenu({ layoutProps }) {
    const location = useLocation();
    const getMenuItemActive = (url) => {
        return checkIsActive(location, url) ? "menu-item-active" : "";
    }

    return <div
        id="kt_header_menu"
        className={`header-menu header-menu-mobile ${layoutProps.ktMenuClasses}`}
        {...layoutProps.headerMenuAttributes}
    >
        {/*begin::Header Nav*/}
        <ul className={`menu-nav ${layoutProps.ulClasses}`}>
            {/*begin::1 Level*/}
            {/*             
            <li className={`menu-item menu-item-rel ${getMenuItemActive('/set-skills/choose')}`}>
                <NavLink className="menu-link" style={{backgroundColor:"#7673C0"}} to="/set-skills/choose">
                    <span className="menu-text" style={{color:"white"}}>Grow Your Skill!</span>
                {layoutProps.rootArrowEnabled && (<i className="menu-arrow" />)}
                </NavLink>
            </li> */}
            <div className="mt-2">
                <h5><b>Aplikasi ini dalam Beta Version</b></h5>
                <p>Nantikan peluncuran fitur-fitur baru lainnya. Stay tuned.</p>
            </div>
            {/*end::1 Level*/}
        </ul>
        <div className="d-flex align-items-baseline mt-5 mr-5">
            <h5 className="font-weight-bold my-2 mr-5">
                {/*<small></small>*/}
            </h5>

        </div>
        {/*end::Header Nav*/}
    </div>;
}
