import scAxios from "../scAxios";
import acsAxios from "../acsAxios";

export function getUserProfilePicture() {
  const config = {
    headers: {
      "accept": "application/octet-stream"
    },
    responseType: "blob",
  }
  return scAxios.get('/users/profilePicture', config);
}

export function uploadUserProfilePicture(image) {
  const formData = new FormData();
  formData.append('ImageData', image)
  const config = {
    headers: {
      'content-type': 'multipart/form-data'
    }
  }
  return acsAxios.post('/self/picture', formData, config);
}

export function deleteUserProfilePicture(rid) {
  return acsAxios.delete(`/self/picture/${rid}`);
}

export function getUserBasicInformation() {
  return scAxios.get('/users/basicInformation');
}

export function updateUserBasicInformation(data) {
  return scAxios.post('/users/basicInformation', data);
}

export function getUserProfileCompletion() {
  return scAxios.get('/users/profileCompletion');
}

export function getUserSkills() {
  return scAxios.get('/users/skills');
}

export function getUserMainSkills() {
  return scAxios.get('/users/skills/main');
}

export function getUserChildrenSkills(parentId) {
  return scAxios.get('/users/skills/children/' + parentId);
}

export function getUserSkillsTree(parentId, maxLevel = 4) {
  return scAxios.get(`/users/skills/tree/sub/${parentId}?maxLevel=${maxLevel}`);
}

export function getUserSuggestedSkills() {
  return scAxios.get('/users/skills/suggested');
}

export function getUserSuggestedSkillsX(rootId, projection) {
  return scAxios.get(`/users/skills/suggested/${rootId}/${projection}`);
}

export function getUserSuggestedSkillsXX(projectedSkills) {
  return scAxios.post(`/users/skills/suggested`, projectedSkills);
}

export function getUserSuggestedCourses(projectedSkills) {
  return scAxios.post(`/users/courses/suggested`, projectedSkills);
}

export function getUserTechSkills() {
  return scAxios.get('/users/techSkills');
}

export function searchUserTechSkills(keyword) {
  return scAxios.get('/Skills/technologySkills/search/' + keyword + '/10');
}

export function searchUserTechSkillChip(keyword) {
  return scAxios.get('/Skills/options/search/' + keyword + '/10');
}

export function addUserSkills(skillData) {
  return scAxios.post('/users/skills/add', { skillChips: skillData });
}

export function removeUserSkills(skillChips) {
  return scAxios.post('/users/skills/remove', { skillChips: skillChips });
}

export function getUserExperiences() {
  return scAxios.get('/users/occupations');
}

export function addUserExperience(data) {
  return scAxios.post('/users/occupations/add', data);
}

export function updateUserExperience(data) {
  return scAxios.post('/users/occupations/update', {
    userOccupationId: data.id,
    edited: {
      location: data.location,
      companyName: data.companyName,
      occupationId: data.occupationId,
      occupationName: data.occupationName,
      startDate: data.startDate,
      endDate: data.endDate,
      title: data.title
    }
  });
}

export function removeUserExperience(userOccupationId) {
  return scAxios.delete(`/users/occupations/${userOccupationId}`);
}

export function getUserEducations() {
  return scAxios.get('/users/educations');
}

export function addUserEducation(data) {
  return scAxios.post('/users/educations/add', data);
}

export function updateUserEducation(data) {
  return scAxios.post('/users/educations/update', {
    userEducationId: data.id,
    edited: {
      schoolName: data.schoolName,
      degree: data.degree,
      fieldOfStudy: data.fieldOfStudy,
      startYear: data.startYear,
      endYear: data.endYear
    }
  });
}

export function removeUserEducation(userEducationId) {
  return scAxios.delete(`/users/educations/${userEducationId}`);
}

export function getUserLineChartSkillHistory(level, parentId) {
  return scAxios.get(`/users/skills/history/chart/line/${level}/${parentId}`);
}

export function getUserBarChartSkillHistory() {
  return scAxios.get(`/users/skills/history/chart/bar`);
}

export function getProfileOverview(userId) {
  return scAxios.get(`/users/profile/${userId}`);
}

export function getProfilePicture(userId) {
  const config = {
    headers: {
      "accept": "application/octet-stream"
    },
    responseType: "blob",
  }
  return scAxios.get(`/users/picture/${userId}`, config);
}

export function getAcsUserProfileByToken() {
  return acsAxios.get('/self/profile');
}

export function updateUserProfile(data) {
  return acsAxios.post('/self/profile', data);
}

export function getUserAddressesByToken() {
  return acsAxios.get('/self/addresses');
}

export function logLink(qs) {
  return acsAxios.post('/self/log/link', { QS: qs });
}

export function logIdle(qs) {
  return acsAxios.post('/self/log/idle', { QS: qs });
}

export function logWakeup(qs) {
  return acsAxios.post('/self/log/wakeup', { QS: qs });
}
  
export function getUserTrainings() {
  return scAxios.get('/users/training');
}

export function addUserTraining(data) {
  return scAxios.post('/users/training', data);
}

export function updateUserTraining(id, data) {
  return scAxios.patch(`/users/training/${id}`, data);
}

export function removeUserTraining(id) {
  return scAxios.delete(`/users/training/${id}`);
}

export function getUserSkillsTreeMap() {
  return scAxios.get('/users/skills/chart/treemap');
}