/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React, { lazy, useEffect } from "react";
import { Switch, Route, useLocation } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import { useGTMDispatch } from "@elgorditosalsero/react-gtm-hook"
import { Layout } from "../_metronic/layout";
import { apiConfig } from "../config/api.config";

// import BasePage from "./BasePage";
// import { Logout, AuthPage } from "./modules/Auth";
// import MainPage from "./pages/Platform/MainPage/index";
import ProfileOverview from "./modules/ProfileOverview/index";
// import ErrorsPage from "./modules/ErrorsExamples/ErrorsPage";
// import CallbackPage from "./modules/Auth/pages/CallbackPage";
// import Logout from "./modules/Auth/pages/Logout";
// import PaymentPage from "./pages/Platform/pages/Payment";
// import Landing from "./pages/Platform/Landing/index";
// import MicroClassIndex from "./pages/Platform/MicroClass/index";
// import MicroClassDetail from "./pages/Platform/MicroClass/Detail";
// import MicroClassLearning from "./pages/Platform/MicroClass/Learning";
// import MicroClassExam from "./pages/Platform/MicroClass/Exam";
// import AboutUs from "./pages/Platform/AboutUs/index";
// import FAQs from "./pages/Platform/FAQs/index";
// import TermsAndConditions from "./pages/Platform/TermsAndConditions/index";
// import LearnersBenefit from "./pages/Platform/LearnersBenefit/index";
// import TeachersBenefit from "./pages/Platform/TeachersBenefit/index";
// import Ebook from "./pages/Platform/Ebook/index";
// import PrivacyPolicy from "./pages/Platform/PrivacyPolicy/index";

import { logLink } from "../redux/crud/users.crud";
import ScrollToTop from "../utils/scrollToTop";

/* begin::Lazy */
const BasePage = lazy(() => import("./BasePage"));
const MainPage = lazy(() => import("./pages/Platform/MainPage/index"));
const ErrorsPage = lazy(() => import("./modules/ErrorsExamples/ErrorsPage"));
const CallbackPage = lazy(() => import("./modules/Auth/pages/CallbackPage"));
const Logout = lazy(() => import("./modules/Auth/pages/Logout"));
const PaymentPage = lazy(() => import("./pages/Platform/pages/Payment"));
const Landing = lazy(() => import("./pages/Platform/Landing/index"));
const MicroClassIndex = lazy(() => import("./pages/Platform/MicroClass/index"));
const MicroClassDetail = lazy(() => import("./pages/Platform/MicroClass/Detail"));
const MicroClassLearning = lazy(() => import("./pages/Platform/MicroClass/Learning"));
const MicroClassExam = lazy(() => import("./pages/Platform/MicroClass/Exam"));
const SkillDrillExam = lazy(() => import("./pages/Platform/SkillDrill/Exam"));
const SkillDrillIndex = lazy(() => import("./pages/Platform/SkillDrill/index"));
const SkillDrillDetail = lazy(() => import("./pages/Platform/SkillDrill/Detail"));
const AboutUs = lazy(() => import("./pages/Platform/AboutUs/index"));
const FAQs = lazy(() => import("./pages/Platform/FAQs/index"));
const TermsAndConditions = lazy(() => import("./pages/Platform/TermsAndConditions/index"));
const LearnersBenefit = lazy(() => import("./pages/Platform/LearnersBenefit/index"));
const TeachersBenefit = lazy(() => import("./pages/Platform/TeachersBenefit/index"));
const Ebook = lazy(() => import("./pages/Platform/Ebook/index"));
const CertificateValidation = lazy(() => import("./pages/Platform/CertificateValidation/index"));
const PrivacyPolicy = lazy(() => import("./pages/Platform/PrivacyPolicy/index"));
const Survey = lazy(() => import("./pages/Platform/Survey/index"));
/* end::Lazy */

export function Routes() {
    const isExpired = (tokenExpiryTime) => {
        const now = new Date();
        if (tokenExpiryTime === undefined) {
            return false;
        }
        return now <= Date.parse(tokenExpiryTime);
    };

    const { isAuthorized } = useSelector(
        ({ auth }) => ({ isAuthorized: auth.authToken != null && isExpired(auth.tokenExpiryTime) }),
        shallowEqual
    );

    const location = useLocation();
    const sendDataToGTM = useGTMDispatch();

    useEffect(() => {
        logLink(window.location.pathname);
        sendDataToGTM({ event: "location", value: window.location.pathname });
    }, [location]);

    return (
        <ScrollToTop>
            <Switch>
                <PlatformLayoutRoute exact path="/" component={Landing} />
                <Route exact path="/courses/omc/:courseId/lesson" component={MicroClassLearning} />
                <Route path="/courses/omc/:courseId/lesson/:lessonHash" component={MicroClassLearning} />
                <PlatformLayoutRoute path="/courses/omc/:findKey/:findValue" component={MicroClassDetail} />
                <PlatformLayoutRoute path="/courses/omc" component={MicroClassIndex} />
                <PlatformLayoutRoute path="/courses/detail" component={MicroClassDetail} />
                <PlatformLayoutRoute path="/skilldrills/exam/:examId/:accessCode" component={SkillDrillExam} />
                <PlatformLayoutRoute path="/skilldrills/:findKey/:findValue" component={SkillDrillDetail} />
                <PlatformLayoutRoute path="/skilldrills" component={SkillDrillIndex} />
                <Route path="/courses/learning" component={MicroClassLearning} />
                <PlatformLayoutRoute path="/courses/exam/:examId/:accessCode" component={MicroClassExam} />
                <PlatformLayoutRoute path="/payment/:trxId/:status" component={PaymentPage} />
                <PlatformLayoutRoute path="/about-us" component={AboutUs} />
                <PlatformLayoutRoute path="/faqs" component={FAQs} />
                <PlatformLayoutRoute path="/terms-and-conditions" component={TermsAndConditions} />
                <PlatformLayoutRoute path="/learners-benefit" component={LearnersBenefit} />
                <PlatformLayoutRoute path="/teachers-benefit" component={TeachersBenefit} />
                <PlatformLayoutRoute path="/e-book" component={Ebook} />
                <PlatformLayoutRoute path="/certificate/validation/:hash" component={CertificateValidation} />
                <PlatformLayoutRoute path="/privacy-policy" component={PrivacyPolicy} />
                <PlatformLayoutRoute path="/survey" component={Survey} />
                
                <Route path="/auth/callback" component={CallbackPage} />
                <Route path="/logout" component={Logout} />

                {isAuthorized ? (
                    <Layout>
                        <BasePage />
                    </Layout>
                ) : (
                    <Route component={() => {
                        window.location.href = `${apiConfig.apiUrl.skillcard}/users/auth`;
                    }}>
                    </Route>
                )}

                {/* <Route path="/profile" component={ProfileOverview} /> */}
                <Route path="/error" component={ErrorsPage} />
            </Switch>
        </ScrollToTop>
    );
}

const PlatformLayoutRoute = ({ component: Component, ...rest }) => {
    return (
        <Route {...rest} render={props => (
            <MainPage>
                <Component {...props} />
            </MainPage>
        )} />
    )
};