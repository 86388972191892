const fontAvenirMedium = "Avenir Medium";
const fontAvenirBold = "Avenir Black";

export default {
    pageTitle: {
        fontFamily: fontAvenirBold,
        fontSize: 20,
        fontWeight: "bold",
        color: "#0F375B"
    },
    learningSkill: {
        padding: 10,
        textAlign: "center",
    },
    paper: {
        
        padding: 30,
        borderRadius: 16,
        textAlign: "left",
        backgroundColor: "#F3F3F8",
        color: "#000000"
    },
    instructionPaperStyle: {
        padding: 10,
        borderRadius: 16,
        textAlign: "left",
        background: "rgba(0, 0, 0, 0.8)",
        opacity: 0.8,
        width: 300,
        color: "#FFFFFF"
    },
    paperNoBackground: {
        
        padding: 30,
        borderRadius: 16,
        textAlign: "left",
        backgroundColor: "#FFFFFF",
        color: "#000000"
    },
    skillCardPaper: {
        
        padding: 30,
        borderRadius: 16,
        textAlign: "left",
        backgroundColor: "#F3F3F8",
        color: "#0F375B"
    },
    skillCardPaperHeaderStrip: {
        
        borderRadius: 8,
        borderLeft: "25px solid transparent",
        borderRight: "25px solid transparent",
        height: 10,
        position: "sticky",
        marginBottom: -13,
        marginLeft: -4,
        width: "101%"
    },
    learningSkillIcon: {
        
        minWidth: 50,
        maxWidth: 50,
        minHeight: 50,
        maxHeight: 50
    },
    paperTitle: {
        
        marginTop: 10,
        marginBottom: 30,
        fontSize: 18
    },
    textInput: {
        width: "100%",
        '& .MuiInputBase-root': {
            
            fontSize: 16,
            fontWeight: 400,
            color: "#0F375B"
        },
        '& .MuiInputLabel-root': {
            
            fontSize: 15,
            color: "#96A5B8"
        },
        '& .MuiInputLabel-shrink': {
            
            fontSize: 18,
            color: "#96A5B8"
        }, '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: 'red',
            },
            '&:hover fieldset': {
                borderColor: 'yellow',
            },
            '&.Mui-focused fieldset': {
            },
        },
    },
    datePicker: {
        marginTop: 0,
        width: "100%",
        '& .MuiInputBase-root': {
            
            fontSize: 15,
            fontWeight: 400,
            color: "#0F375B"
        },
        '& .MuiInputLabel-root': {
            
            fontSize: 15,
            color: "#96A5B8"
        },
        '& .MuiInputLabel-shrink': {
            
            fontSize: 18,
            color: "#96A5B8"
        },
        '& label.Mui-focused': {
        },
        '& .MuiInput-underline:before': {
        },
        '& .MuiInput-underline:after': {
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
            },
            '&:hover fieldset': {
            },
            '&.Mui-focused fieldset': {
            },
        },
        '& .MuiSvgIcon-root': {
            color: "#0F375B"
        },
        '& .MuiPickersToolbar-toolbar': {
        }
    },
    experiencesItemTitle: {
        textAlign: "left"
    },
    educationsList: {
        padding: 10,
        borderRadius: 16,
        textAlign: "left",
        fontSize: 15,
    },
    divider: {
        backgroundColor: "#2233FE"
    },
    stepper: {
        backgroundColor: "#FFFFFF",
        '& .MuiSvgIcon-root': {
            
            fontSize: 40
        },
        '& .MuiStepIcon-root': {
            
            fontSize: 40
        },
        '& .MuiStepLabel-label': {
            
            fontSize: 16,
            color: "#0F375B"
        },
        '& .MuiButton-root': {
            '& .Mui-disabled': {
                color: "#0F375B"
            },
            color: "#0F375B"
        },
        '& .Mui-disabled': {
            color: "#0F375B"
        }
    },
    checkBox: {
        color: "#0F375B",
        '& .MuiIconButton-label': {
            color: "#0F375B"
        }
    },
    button:{
        '& .MuiIconButton-label': {
            color: "red",
            fontSize: 24
        }
        
    }
}
