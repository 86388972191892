/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,no-undef */
import React from "react";
import { injectIntl } from "react-intl";
import { connect, shallowEqual, useSelector } from "react-redux";
import SVG from "react-inlinesvg";
import { useHistory } from "react-router-dom";
import { toAbsoluteUrl } from "../../../../_helpers";
// import { getUserProfilePicture } from "../../../../../redux/crud/users.crud";
import * as auth from "../../../../../redux/crud/authRedux";
import { apiConfig } from "../../../../../config/api.config";

function QuickUser() {
  const history = useHistory();
  const { user } = useSelector(state => state.auth);
  const [profilePicture, setProfilePicture] = React.useState(null);
  const logoutClick = () => {
    const toggle = document.getElementById("kt_quick_user_toggle");
    if (toggle) {
      toggle.click();
    }
    history.push("/logout");
  };

  const { userProfile } = useSelector(
    ({ auth }) => ({
      userProfile: auth !== undefined ? auth.userProfile : undefined
    }),
    shallowEqual
  );

  React.useEffect(() => {
    /*
    const fetchData = async () => {
      getUserProfilePicture()
        .then(({ data }) => {
          if (data.size === 0) {
            setProfilePicture(null);
          } else {
            const urlCreator = window.URL || window.webkitURL;
            const imageURL = urlCreator.createObjectURL(data);
            setProfilePicture(imageURL);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    };

    fetchData();
    */
  }, []);

  return (
    <div id="kt_quick_user" className="offcanvas offcanvas-right offcanvas p-10">
      <div className="offcanvas-header d-flex align-items-center justify-content-between pb-5">
        <h3 className="font-weight-bold m-0">
          Info Saya
        </h3>
        <a
          href="#"
          className="btn btn-xs btn-icon btn-light btn-hover-primary"
          id="kt_quick_user_close"
        >
          <i className="ki ki-close icon-xs text-muted" />
        </a>
      </div>

      <div
        className="offcanvas-content pr-5 mr-n5"
      >
        <div className="d-flex align-items-center mt-5">
          <div
            className="symbol symbol-100 mr-5"
          >
            <div className="symbol-label">
              <img alt={user === undefined ? null : user.fullname} src={userProfile ? `${apiConfig.apiUrl.acs}/picture/${userProfile.RID}/small` : '/images/users/blank.png' } className="symbol-label font-size-h5 font-weight-bold" style={{ borderRadius: "50%" }} loading="lazy" />
            </div>
          </div>
          <div className="d-flex flex-column">
            <a
              href="#"
              className="font-weight-bold font-size-h5 text-black"
              style={{ color: "black" }}
            >
              {user === undefined ? null : user.fullname}
            </a>
            <div className="text-muted mt-1">{user === undefined ? null : user.companyName}</div>
            <div className="navi mt-2">
              <a href="#" className="navi-item">
                <span className="navi-link p-0 pb-2">
                  <span className="navi-icon mr-1">
                    <span className="svg-icon-lg svg-icon-primary">
                      <SVG
                        src={toAbsoluteUrl(
                          "/images/svg/icons/Communication/Mail-notification.svg"
                        )}
                      ></SVG>
                    </span>
                  </span>
                  <span className="navi-text text-muted text-hover-primary">
                    {user === undefined ? null : user.email}
                  </span>
                </span>
              </a>
            </div>
            {/* <Link to="/logout" className="btn btn-light-primary btn-bold">
                Sign Out
              </Link> */}
            <a href="/about" className="navi-item btn btn-primary btn-bold mb-2">Lihat Profil Saya</a>
            <button className="btn btn-light-danger btn-bold" onClick={logoutClick}>Log Out</button>
          </div>
        </div>

        <div className="separator separator-dashed mt-8 mb-5" />

        {/* <div className="navi navi-spacer-x-0 p-0">
          <a href="/user-profile/general-info" className="navi-item">
            <div className="navi-link">
              <div className="symbol symbol-40 bg-light mr-3">
                <div className="symbol-label">
                  <span className="svg-icon svg-icon-md svg-icon-success">
                    <SVG
                      src={toAbsoluteUrl(
                        "/images/svg/icons/General/Notification2.svg"
                      )}
                    ></SVG>
                  </span>
                </div>
              </div>
              <div className="navi-text">
                <div className="font-weight-bold">My Profile</div>
                <div className="text-muted">
                  Account settings and more
                    {" "}
                </div>
              </div>
            </div>
          </a>
        </div> */}
      </div>
    </div>
  );
}

export default injectIntl(
  connect(
    null,
    auth.actions
  )(QuickUser)
);