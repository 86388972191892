/* eslint-disable no-restricted-imports */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useMemo } from "react";
import { injectIntl } from "react-intl";
import { connect, shallowEqual, useSelector } from "react-redux";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import objectPath from "object-path";
import { useHtmlClassService } from "../../_core/MetronicLayout";
import { UserProfileDropdown } from "./dropdowns/UserProfileDropdown";
import { apiConfig } from "../../../../config/api.config";
import * as auth from "../../../../redux/crud/authRedux";

export function QuickUserToggler() {
  const { user } = useSelector(state => state.auth);
  const [profilePicture, setProfilePicture] = React.useState(null);

  const { userProfile } = useSelector(
    ({ auth }) => ({
      userProfile: auth !== undefined ? auth.userProfile : undefined
    }),
    shallowEqual
  );

  React.useEffect(() => {
    /*
    const fetchData = async () => {
      getUserProfilePicture()
        .then(({ data }) => {
          if (data.size === 0) {
            setProfilePicture(null);
          } else {
            const urlCreator = window.URL || window.webkitURL;
            const imageURL = urlCreator.createObjectURL(data);
            setProfilePicture(imageURL);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    };

    fetchData();
    */
  }, []);

  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => {
    return {
      offcanvas: objectPath.get(uiService.config, "extras.user.layout") === "offcanvas",
    };
  }, [uiService]);

  return (<>
    {layoutProps.offcanvas && (<OverlayTrigger
      placement="bottom"
      overlay={<Tooltip id="quick-user-tooltip">View user</Tooltip>}
    >
      <div className="topbar-item">
        <div className="btn btn-icon w-auto btn-clean d-flex align-items-center btn-lg px-2"
          id="kt_quick_user_toggle">
          <>
            <span className="symbol symbol-35 symbol-light-success">
              <img alt={user === undefined ? null : user.fullname} src={userProfile ? `${apiConfig.apiUrl.acs}/picture/${userProfile.RID}/small` : '/images/users/blank.png' } className="symbol-label font-size-h5 font-weight-bold" style={{ borderRadius: "50%" }} />
            </span>
          </>
        </div>
      </div>
    </OverlayTrigger>)}

    {!layoutProps.offcanvas && (<UserProfileDropdown />)}
  </>
  );
}
