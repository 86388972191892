import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { put, takeLatest } from "redux-saga/effects";
import { getAcsUserProfileByToken, getUserByToken } from "./authCrud";
import * as userRedux from "../reducers/userRedux";

export const actionTypes = {
  Login: "[Login] Action",
  Logout: "[Logout] Action",
  Register: "[Register] Action",
  UserRequested: "[Request User] Action",
  UserLoaded: "[Load User] Auth API",
  UserProfileRequested: "[Request UserProfile] Action",
  UserProfileLoaded: "[Load UserProfile] Auth API"
};

const initialAuthState = {
  user: undefined,
  userProfile: undefined,
  authToken: undefined,
  tokenExpiryTime: undefined
};

export const reducer = persistReducer(
  { storage, key: "playbook-auth", whitelist: ["user", "userProfile", "authToken", "tokenExpiryTime"] },
  (state = initialAuthState, action) => {
    switch (action.type) {
      case actionTypes.Login: {
        const { authToken, tokenExpiryTime } = action.payload;

        return { authToken, tokenExpiryTime, user: undefined, userProfile: undefined };
      }

      case actionTypes.Register: {
        const { authToken } = action.payload;

        return { authToken, user: undefined, userProfile: undefined };
      }

      case actionTypes.Logout: {
        // TODO: Change this code. Actions in reducer aren't allowed.
        return initialAuthState;
      }

      case actionTypes.UserLoaded: {
        const { user } = action.payload;
        return { ...state, user };
      }

      case actionTypes.UserProfileLoaded: {
        const { userProfile } = action.payload;
        return { ...state, userProfile };
      }

      default:
        return state;
    }
  }
);

export const actions = {
  login: (authToken, tokenExpiryTime) => ({ type: actionTypes.Login, payload: { authToken, tokenExpiryTime } }),
  register: authToken => ({
    type: actionTypes.Register,
    payload: { authToken }
  }),
  logout: () => ({ type: actionTypes.Logout }),
  requestUser: user => ({ type: actionTypes.UserRequested, payload: { user } }),
  fulfillUser: user => ({ type: actionTypes.UserLoaded, payload: { user } }),
  requestUserProfile: userProfile => ({ type: actionTypes.UserProfileRequested, payload: { userProfile }}),
  fulfillUserProfile: userProfile => ({ type: actionTypes.UserProfileLoaded, payload: { userProfile }})
};

export function* saga() {
  yield takeLatest(actionTypes.Login, function* loginSaga() {
    // yield put(actions.requestUser());
    yield put(actions.requestUserProfile());
  });

  yield takeLatest(actionTypes.Logout, function* logoutSaga() {
    yield put(userRedux.actions.clearUserProfileState());
  });

  yield takeLatest(actionTypes.Register, function* registerSaga() {
    // yield put(actions.requestUser());
    yield put(actions.requestUserProfile());
  });

  yield takeLatest(actionTypes.UserRequested, function* userRequested() {
    const { data: user } = yield getUserByToken();

    yield put(actions.fulfillUser(user));
  });

  yield takeLatest(actionTypes.UserProfileRequested, function* UserProfileRequested() {
    const { data: result } = yield getAcsUserProfileByToken();
    console.log(result);

    if (result.Success) {
      yield put(actions.fulfillUserProfile(result.Data));
    }
  });
}
