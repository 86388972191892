import React, { Component } from "react";
import { withStyles } from "@material-ui/core";
import { Grid, Container, Paper } from "@material-ui/core";
import globalStyles from "../../../styles/globalStyles";
import GeneralInfo from "./GeneralInfo";
import Experience from "./Experiences";
import Education from "./Educations";
import OmcHistory from "./OmcHistory";
import { getProfileOverview, getProfilePicture } from "../../../redux/crud/users.crud";

const qs = require('qs');

const styles = theme => ({
    learningSkill: globalStyles.learningSkill,
    paper: globalStyles.paper,
    learningSkillIcon: globalStyles.learningSkillIcon,
    paperTitle: globalStyles.paperTitle
})

class ProfileOverviewPage extends Component {
    constructor() {
        super();

        this.state = {
            profilePicture: null,
            data: {
                basicInformation: {},
                occupations: [],
                educations: [],
                omcHistory: []
            }
        };
    }

    componentDidMount() {
        const userId = qs.parse(this.props.history.location.search, { ignoreQueryPrefix: true }).id;
        getProfileOverview(userId)
            .then(({ data }) => {
                this.setState({ data: data });
            });
        getProfilePicture(userId)
            .then(({ data }) => {
                const urlCreator = window.URL || window.webkitURL;
                const imageURL = urlCreator.createObjectURL(data);
                this.setState({
                    profilePicture: imageURL
                });
            });
    }

    render() {
        return (
            <Container maxWidth="lg">
                <Grid justify="center" container spacing={4}>
                    <Grid justify="center" container item>
                        <GeneralInfo profilePicture={this.state.profilePicture} data={this.state.data.basicInformation}/>
                    </Grid>
                    <Grid container item spacing={4}>
                        <Grid item md={6}>
                            <Experience data={this.state.data.occupations} />
                        </Grid>
                        <Grid item md={6}>
                            <Education data={this.state.data.educations} />
                        </Grid>
                        <Grid item md={6}>
                            <OmcHistory data={this.state.data.omcHistory} />
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        );
    }
}

export default withStyles(styles, { withTheme: true })(ProfileOverviewPage)
