import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { put, takeLatest } from "redux-saga/effects";
import { toCamel } from "../../app/utils";
import { getUserCourses, getCartItems, getUserWorkshops } from "../crud/courses.crud";

export const actionTypes = {
  FetchUserCourses: "[Fetch UserCourses] Action",
  UserCoursesLoaded: "[Load UserCourses] Courses API",
  FetchUserWorkshops: "[Fetch UserWorkshops] Action",
  UserWorkshopsLoaded: "[Load UserWorkshops] Workshops API",
  SetCourseDownloadUpdateCounter: "[Set SetCourseDownloadUpdateCounter] Action",
  FetchUserCartItems: "[Fetch UserCartItems] Action",
  UserCartItemsLoaded: "[Load UserCartItems] User Cart Items API"
};

const initialUserProfileState = {
  userCourses: [],
  userWorkshops: [],
  courseDownloadUpdateCounter: 0,
  userCartItems: []
};

export const reducer = persistReducer(
  { storage, key: "playbook-user-courses", whitelist: ["userCourses", "userCartItems"] },
  (state = initialUserProfileState, action) => {
    switch (action.type) {
      case actionTypes.UserCoursesLoaded: {
        const { userCourses } = action.payload;
        return { ...state, userCourses };
      }

      case actionTypes.UserWorkshopsLoaded: {
        const { userWorkshops } = action.payload;
        return { ...state, userWorkshops };
      }

      case actionTypes.SetCourseDownloadUpdateCounter: {
        const { courseDownloadUpdateCounter } = action.payload;
        return { ...state, courseDownloadUpdateCounter };
      }

      case actionTypes.UserCartItemsLoaded: {
        const { userCartItems } = action.payload;
        return { ...state, userCartItems };
      }

      default:
        return state;
    }
  }
);

export const actions = {
  fetchUserCourses: () => ({ type: actionTypes.FetchUserCourses }),
  fulfillUserCourses: userCourses => ({ type: actionTypes.UserCoursesLoaded, payload: { userCourses } }),
  fetchUserWorkshops: () => ({ type: actionTypes.FetchUserWorkshops }),
  fulfillUserWorkshops: userWorkshops => ({ type: actionTypes.UserWorkshopsLoaded, payload: { userWorkshops } }),
  setCourseDownloadUpdateCounter: courseDownloadUpdateCounter => ({ type: actionTypes.SetCourseDownloadUpdateCounter, payload: { courseDownloadUpdateCounter }}),
  fetchUserCartItems: () => ({ type: actionTypes.FetchUserCartItems }),
  fulfillUserCartitems: userCartItems => ({ type: actionTypes.UserCartItemsLoaded, payload: { userCartItems } })
};

export function* saga() {
  yield takeLatest(actionTypes.FetchUserCourses, function* fetchUserCoursesSaga() {
    const { data: result } = yield getUserCourses();
    if (result.Success) {
      yield put(actions.fulfillUserCourses(toCamel(result.Data)));
    }
  });

  yield takeLatest(actionTypes.FetchUserWorkshops, function* fetchUserWorkshopsSaga() {
    const { data: result } = yield getUserWorkshops();
    if (result.Success) {
      yield put(actions.fulfillUserWorkshops(toCamel(result.Data)));
    }
  }); 

  yield takeLatest(actionTypes.FetchUserCartItems, function* fetchUserCartItemsSaga() {
    const { data: result } = yield getCartItems();
    if (result.Success) {
      yield put(actions.fulfillUserCartitems(toCamel(result.Data)));
    }
  });
}
