import scAxios from "../scAxios";

export function searchSkillOptions(keyword) {
  return scAxios.get(`/skills/options/search/${keyword}`);
}

export function getSoftSkills() {
  return scAxios.get('/skills/tree/leaves');
}

export function getSkillTreeNodes(level) {
  return scAxios.get(`/skills/tree/nodes/${level}`);
}

export function getMainSkillTree(ids) {
  return scAxios.post('/skills/tree/main', { ids });
}