/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useRef } from "react";
import { injectIntl } from "react-intl";
import { connect, shallowEqual, useSelector } from "react-redux";
import { useLocation } from "react-router";
import { NavLink, useHistory } from "react-router-dom";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl, checkIsActive } from "../../../../_helpers";
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import { uploadUserProfilePicture, deleteUserProfilePicture } from "../../../../../redux/crud/users.crud";
import PropTypes from 'prop-types';
import { useSpring, animated } from 'react-spring/web.cjs'; // web.cjs is required for IE 11 support
import * as auth from "../../../../../redux/crud/authRedux";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5]
  },
}));

const Fade = React.forwardRef(function Fade(props, ref) {
  const { in: showProfilePicture, children, onEnter, onExited, ...other } = props;
  const style = useSpring({
    from: { opacity: 0 },
    to: { opacity: showProfilePicture ? 1 : 0 },
    onStart: () => {
      if (showProfilePicture && onEnter) {
        onEnter();
      }
    },
    onRest: () => {
      if (!showProfilePicture && onExited) {
        onExited();
      }
    },
  });

  return (
    <animated.div ref={ref} style={style} {...other}>
      {children}
    </animated.div>
  );
});

Fade.propTypes = {
  children: PropTypes.element,
  in: PropTypes.bool.isRequired,
  onEnter: PropTypes.func,
  onExited: PropTypes.func,
};

function AsideMenuList(props) {
  // const { user } = useSelector(state => state.auth);
  const classes = useStyles();
  const { layoutProps } = props;

  const [init, setInit] = React.useState(false);
  const [profilePicture, setProfilePicture] = React.useState(null);
  const [showProfilePicture, setShowProfilePicture] = React.useState(false);

  const { userProfile } = useSelector(
    ({ auth }) => ({
      userProfile: auth !== undefined ? auth.userProfile : undefined
    }),
    shallowEqual
  );

  React.useEffect(() => {
    props.requestUserProfile();
  }, [props]);

  const location = useLocation();
  const history = useHistory();
  const toSetSkillsWizard = () => {
    history.push('/set-skills/choose');
  }
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu && "menu-item-active"} menu-item-open `
      : "";
  };

  const [anchorEl, setAnchorEl] = React.useState(null);

  const showChangePicturePopover = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const hideChangePicturePopover = () => {
    setAnchorEl(null);
  };

  const handleChangePicture = (event) => {
    uploadUserProfilePicture(event.target.files[0])
      .then(({ data }) => {
        if (data.Success) {
          props.requestUserProfile();
        }
      })
      .catch((err) => {
        console.log(err);
      });

    hideChangePicturePopover();
  }

  const handleRemovePicture = (event) => {
    if (userProfile) {
      deleteUserProfilePicture(userProfile.RID)
        .then(({ data }) => {
          if (data.Success) {
            setProfilePicture(null);
          }
        })
        .catch((err) => {
          console.log(err);
        });

      hideChangePicturePopover();
    }
  }

  const fileUploader = useRef(null);
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const dateNow = new Date();
  let timeNow = "";

  if (dateNow.getHours() >= 0 && dateNow.getHours() <= 12) {
    timeNow = "Pagi";
  }
  else if (dateNow.getHours() >= 12 && dateNow.getHours() <= 16) {
    timeNow = "Siang";
  }
  else if (dateNow.getHours() >= 16 && dateNow.getHours() <= 19) {
    timeNow = "Sore";
  }
  else {
    timeNow = "Malam";
  }

  const handleShowProfilePicture = () => {
    setShowProfilePicture(true);
    hideChangePicturePopover();
  };

  const handleCloseProfilePicture = () => {
    setShowProfilePicture(false);
  };

  return (
    <>
      {/* begin::Menu Nav */}
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        <li className="px-5 mymenu-item-action menu-item">
          <NavLink className="menu-link" to={"/set-skills/choose"}>
            <span className="menu-text">
              <Button
                color="primary"
                variant="contained"
                style={{
                  fontSize: 14,
                  textTransform: "capitalize",
                  borderRadius: 16,
                  marginBottom: 20
                }}
                onClick={toSetSkillsWizard}
              >
                Grow Your Skill
              </Button>
            </span>
          </NavLink>
        </li>

        {/*begin::1 Level*/}
        <li
          className={`menu-item ${getMenuItemActive("/skillcard", false)} mymenu-item`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/skillcard">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/General/Settings-3.svg")} />
            </span>
            <span className="menu-text navbar-item-text">SkillCard</span>
          </NavLink>
        </li>
        {/*end::1 Level*/}

        {/*begin::1 Level*/}
        <li
          className={`menu-item ${getMenuItemActive("/about", false)} mymenu-item`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/about">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/General/User.svg")} />
            </span>
            <span className="menu-text profile-page-title navbar-item-text">My Career Snapshot</span>
          </NavLink>
        </li>
        {/*end::1 Level*/}

        {/*begin::1 Level*/}
        {/*
        <li
          className={`menu-item ${getMenuItemActive("/progress", false)} mymenu-item`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/progress">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Chart-line1.svg")} />
            </span>
            <span className="menu-text navbar-item-text">Learning Progression</span>
          </NavLink>
        </li>
        */}
        {/*end::1 Level*/}

        {/*begin::1 Level*/}
        <li
          className={`menu-item ${getMenuItemActive("/student/omc", false)} mymenu-item`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/student/omc">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/General/Thunder.svg")} />
            </span>
            <span className="menu-text navbar-item-text">My Courses</span>
          </NavLink>
        </li>
        {/*end::1 Level*/}

        {/*begin::1 Level*/}
        <li
          className={`menu-item ${getMenuItemActive("/student/skilldrill", false)} mymenu-item`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/student/skilldrill">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/General/Thunder-move.svg")} />
            </span>
            <span className="menu-text navbar-item-text">My Skill Drills</span>
          </NavLink>
        </li>
        {/*end::1 Level*/}

        {/*begin::1 Level*/}
        <li
          className={`menu-item ${getMenuItemActive("/cart", false)} mymenu-item`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/cart">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Shopping/Cart2.svg")} />
            </span>
            <span className="menu-text navbar-item-text" >My Cart</span>
          </NavLink>
        </li>
        {/*end::1 Level*/}

        {/*begin::1 Level*/}
        <li
          className={`menu-item ${getMenuItemActive("/ebooks", false)} mymenu-item`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/ebooks">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Home/Book.svg")} />
            </span>
            <span className="menu-text navbar-item-text" >eBooks</span>
          </NavLink>
        </li>
        {/*end::1 Level*/}

        {/*begin::1 Level*/}
        {/* <li
          className={`menu-item ${getMenuItemActive("/set-skills", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/set-skills">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/images/svg/icons/Design/Layers.svg")} />
            </span>
            <span className="menu-text">Tambah Keahlian</span>
          </NavLink>
        </li> */}
        {/*end::1 Level*/}


        {/*begin::1 Level*/}
        {/* <li
          className={`menu-item ${getMenuItemActive("/about", false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/about">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/images/svg/icons/Home/Library.svg")} />
            </span>
            <span className="menu-text">About</span>
          </NavLink>
        </li> */}
        {/*end::1 Level*/}

      </ul>

      {/* end::Menu Nav */}
    </>
  );
}

export default injectIntl(
  connect(
    null,
    auth.actions
  )(AsideMenuList)
);
