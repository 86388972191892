export const apiConfig = {
    apiUrl: {
      acs: "https://acsapi.playbook.plus",
      course: "https://courseapi.playbook.plus",
      orpa: "https://orpa.playbook.plus",
      aspla: "https://aspla.playbook.plus",
      skillcard: "https://scapi.playbook.plus",
      docu: "https://docuapi.playbook.plus"
    },
    authHeaderKey: "acsp",
    applicationId: "fbe0db28a0954f9d"
};
