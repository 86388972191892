import scAxios from "../scAxios";
import courseAxios from "../courseAxios";

export function getOmcHistory() {
  return scAxios.get('/courses/omc/history');
}

export function getCoursesBySkills({ Filters = {}, Orders = {}, Offset, Limit, RootSkills}) {
  return courseAxios.post('/skill/root/courses', {
    Filters,
    Orders,
    ...(RootSkills ? { RootSkills } : {}),
    ...(Offset ? { Offset } : {}),
    ...(Limit ? { Limit } : {})
  });
}

export function getUserAvailableCourses({ Filters = {}, Orders = {}, Offset, Limit, RootSkills}) {
  return courseAxios.post(`/my/course/available`, {
    Filters,
    Orders,
    ...(RootSkills ? { RootSkills } : {}),
    ...(Offset ? { Offset } : {}),
    ...(Limit ? { Limit } : {})
  });
}

export function getSuggestedCourseByCourseId(courseId) {
  return courseAxios.get(`/my/course/${courseId}/suggested`);
}

export function getWorkshopsBySkills({ Filters = {}, Orders = {}, Offset, Limit, RootSkills}) {
  return courseAxios.post('/skill/root/workshops', {
    Filters,
    Orders,
    ...(RootSkills ? { RootSkills } : {}),
    ...(Offset ? { Offset } : {}),
    ...(Limit ? { Limit } : {})
  });
}

export function getUserAvailableWorkshops({ Filters = {}, Orders = {}, Offset, Limit, RootSkills}) {
  return courseAxios.post(`/my/workshop/available`, {
    Filters,
    Orders,
    ...(RootSkills ? { RootSkills } : {}),
    ...(Offset ? { Offset } : {}),
    ...(Limit ? { Limit } : {})
  });
}

export function getUserAvailableWorkshopByHash(hash) {
  return courseAxios.get(`/my/workshop/available/hash/${hash}`);
}

export function getUserWorkshopByHash(hash) {
  return courseAxios.get(`/my/workshop/hash/${hash}`);
}

export function checkoutWorkshopByHash(hash) {
  return courseAxios.post(`/my/workshop/${hash}/checkout`);
}

export function getSeries() {
  return courseAxios.get("/data/series");
}

export function getUserCourses() {
  return courseAxios.get('/my/courses');
}

export function getUserCourseByHash(hash) {
  return courseAxios.get(`/my/course/hash/${hash}`);
}

export function getUserCourseBySlug(slug) {
  return courseAxios.get(`/my/course/slug/${slug}`);
}

export function getUserAvailableCourseByHash(hash) {
  return courseAxios.get(`/my/course/available/hash/${hash}`);
}

export function getUserAvailableCourseBySlug(slug) {
  return courseAxios.get(`/my/course/available/slug/${slug}`);
}

export function checkoutCourseByHash(hash) {
  return courseAxios.post(`/my/course/${hash}/checkout`);
}

export function getUserWorkshops() {
  return courseAxios.get(`/my/workshops`);
}

export function getCartContentByTransactionId(trxId) {
  return courseAxios.get(`/my/cart/transaction/${trxId}`);
}

export function getCartItems() {
  return courseAxios.get('/my/cart');
}

export function deleteCartItem(cartItemId) {
  return courseAxios.delete(`/my/cart/item/${cartItemId}/remove`);
}

export function clearCartItems() {
  return courseAxios.post('/my/cart/empty');
}

export function addCoursesToCart(courseIds, ignoreInCart = true, ignoreEnrolled = true) {
  return courseAxios.post('/my/cart/add/course', {
    CourseIDs: courseIds,
    IgnoreInCart: ignoreInCart,
    IgnoreEnrolled: ignoreEnrolled
  });
}

export function selectCart(cartItemId) {
  return courseAxios.post(`/my/cart/item/${cartItemId}/select`)
}

export function cartCheckout() {
  return courseAxios.post('/my/cart/checkout');
}

export function getLessons(courseId) {
  return courseAxios.get(`/my/course/${courseId}/lessons`);
}

export function startLesson(itemHash) {
  return courseAxios.post(`/my/lesson/${itemHash}/start`);
}

export function completeLesson(itemHash) {
  return courseAxios.post(`/my/lesson/${itemHash}/complete`);
}

export function registerExam(courseId, examId, examNo = "") {
  return courseAxios.post(`/my/course/${courseId}/exam/${examId}/register`, { ExamNo: examNo });
}

export function registerWorkshopExam(workshopId, examId, examNo = "") {
  return courseAxios.post(`/my/workshop/${workshopId}/exam/${examId}/register`);
}

export function getUserExams() {
  return courseAxios.get('/my/exams');
}

export function getCertificateValidationData(hash) {
  return courseAxios.get(`/public/certificate/${hash}`)
}