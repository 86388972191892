import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { put, takeLatest } from "redux-saga/effects";
import { toCamel } from "../../app/utils";
import { mergeSkillColorAndBackground } from "../../app/utils/skillcardColors";
import { getSkillTreeNodes } from "../crud/skills.crud";
import { getAcsUserProfileByToken, getUserAddressesByToken, getUserBasicInformation, getUserMainSkills, getUserSkillsTree } from "../crud/users.crud";

export const actionTypes = {
  FetchUserMainSkills: "[Fetch UserMainSkills] Action",
  UserMainSkillsLoaded: "[Load UserMainSkills] Users API",
  SetUserMainSkillsLoading: "[Set UserMainSkills] Action",
  FetchUserSkillWithDetailLv1: "[Fetch UserSkillWithDetailLv1] Action",
  UserSkillWithDetailLv1Loaded: "[Load UserSkillWithDetailLv1] Users API",
  FetchUserBasicInfo: "[Fetch UserBasicInfo] Action",
  UserBasicInfoLoaded: "[Load UserBasicInfo] Users API",
  ClearUserProfileState: "[Clear UserProfile] Action",
  UserProfileRequested: "[Request UserProfile] Action",
  UserProfileLoaded: "[Load UserProfile] Users API",
  UserAddressesRequested: "[Request UserAddresses] Action",
  UserAddressesLoaded: "[Load UserAddresses] Users API"
};

const initialUserProfileState = {
  userMainSkills: [],
  isUserMainSkillsLoading: false,
  userSkillWithDetailLv1: [],
  userBasicInfo: {},
  userProfile: undefined,
  userAddresses: {}
};

export const reducer = persistReducer(
  { storage, key: "playbook-user-profile", whitelist: ["userMainSkills", "isUserMainSkillsLoading", "userSkillWithDetailLv1", "userBasicInfo"] },
  (state = initialUserProfileState, action) => {
    switch (action.type) {
      case actionTypes.UserMainSkillsLoaded: {
        const { userMainSkills } = action.payload;
        return { ...state, userMainSkills };
      }

      case actionTypes.SetUserMainSkillsLoading: {
        const { isUserMainSkillsLoading } = action.payload;
        return { ...state, isUserMainSkillsLoading };
      }

      case actionTypes.UserSkillWithDetailLv1Loaded: {
        const { userSkillWithDetailLv1 } = action.payload;
        return { ...state, userSkillWithDetailLv1 };
      }

      case actionTypes.UserBasicInfoLoaded: {
        const { userBasicInfo } = action.payload;
        return { ...state, userBasicInfo };
      }

      case actionTypes.ClearUserProfileState: {
        return initialUserProfileState;
      }

      case actionTypes.UserProfileLoaded: {
        const { userProfile } = action.payload;
        return { ...state, userProfile };
      }

      case actionTypes.UserAddressesLoaded: {
        const { userAddresses } = action.payload;
        return { ...state, userAddresses };
      }

      default:
        return state;
    }
  }
);

export const actions = {
  fetchUserMainSkills: () => ({ type: actionTypes.FetchUserMainSkills }),
  fulfillUserMainSkills: userMainSkills => ({ type: actionTypes.UserMainSkillsLoaded, payload: { userMainSkills } }),
  setUserMainSkillsLoading: isUserMainSkillsLoading => ({ type: actionTypes.SetUserMainSkillsLoading, payload: { isUserMainSkillsLoading } }),
  fetchUserSkillWithDetailLv1: () => ({ type: actionTypes.FetchUserSkillWithDetailLv1 }),
  fulfillUserSkillWithDetailLv1: userSkillWithDetailLv1 => ({ type: actionTypes.UserSkillWithDetailLv1Loaded, payload: { userSkillWithDetailLv1 } }),
  fetchUserBasicInfo: () => ({ type: actionTypes.FetchUserBasicInfo }),
  fulfillUserBasicInfo: userBasicInfo => ({ type: actionTypes.UserBasicInfoLoaded, payload: { userBasicInfo } }),
  clearUserProfileState: () => ({ type: actionTypes.ClearUserProfileState }),
  requestUserProfile: userProfile => ({ type: actionTypes.UserProfileRequested, payload: { userProfile }}),
  fulfillUserProfile: userProfile => ({ type: actionTypes.UserProfileLoaded, payload: { userProfile }}),
  requestUserAddresses: userAddresses => ({ type: actionTypes.UserAddressesRequested, payload: { userAddresses }}),
  fulfillUserAddresses: userAddresses => ({ type: actionTypes.UserAddressesLoaded, payload: { userAddresses }})
};

export function* saga() {
  yield takeLatest(actionTypes.FetchUserMainSkills, function* fetchUserMainSkillsSaga() {
    yield put(actions.setUserMainSkillsLoading(true));

    const { data: userMainSkills } = yield getUserMainSkills();
    const userMainSkillsWithColor = mergeSkillColorAndBackground(userMainSkills);

    yield put(actions.setUserMainSkillsLoading(false));
    yield put(actions.fulfillUserMainSkills(userMainSkillsWithColor));
  });

  yield takeLatest(actionTypes.FetchUserSkillWithDetailLv1, function* fetchUserSkillWithDetailLv1Saga() {
    const { data: mainSkills } = yield getSkillTreeNodes(1);
    const userSkillWithDetailLv1 = [];
    for (let mainSkill of mainSkills) {
      const { data: subTree } = yield getUserSkillsTree(mainSkill.id);

      let parent = null;
      // find parent node
      for (let node of mergeSkillColorAndBackground(subTree)) {
        if (node.parentId === null) {
          parent = node;
          parent.children = [];
          break;
        }
      }

      if (parent) {
        subTree.forEach((node) => {
          if (node.parentId === parent.skillId) {
            node.color = parent.color;
            parent.children.push(node);
          }
        });
      }

      userSkillWithDetailLv1.push(parent);
    }

    yield put(actions.fulfillUserSkillWithDetailLv1(userSkillWithDetailLv1));
  });

  yield takeLatest(actionTypes.FetchUserBasicInfo, function* fetchUserBasicInfoSaga() {
    const { data: userBasicInfo } = yield getUserBasicInformation();
    yield put(actions.fulfillUserBasicInfo(toCamel(userBasicInfo)));
  });

  yield takeLatest(actionTypes.UserProfileRequested, function* UserProfileRequested() {
    const { data: result } = yield getAcsUserProfileByToken();
    if (result.Success) {
      yield put(actions.fulfillUserProfile(toCamel(result.Data)));
    }
  });

  yield takeLatest(actionTypes.UserProfileRequested, function* UserProfileRequested() {
    const { data: result } = yield getUserAddressesByToken();
    if (result.Success) {
      yield put(actions.fulfillUserAddresses(toCamel(result.Data)));
    }
  });
}
