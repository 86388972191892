export const getSkillColor = (skillId) => {
  switch (skillId) {
    case "1":
      return "#0F375B";
    case "1.1":
      return "#0F375B";
    case "1.2":
      return "#0F375B";
    case "1.3":
      return "#0F375B";
    case "2":
      return "#7473FF";
    case "3":
      return "#00BFE6";
    case "4":
      return "#FCB231";
    case "5":
      return "#ED6F78";
    default:
      return "#000000";
  }
}

export const getSkillTextColor = (skillId) => {
  switch (skillId) {
    case "1":
      return "#FFFFFF";
    case "1.1":
      return "#FFFFFF";
    case "1.2":
      return "#FFFFFF";
    case "1.3":
      return "#FFFFFF";
    case "2":
      return "#FFFFFF";
    case "3":
      return "#000000";
    case "4":
      return "#000000";
    case "5":
      return "#000000";
    default:
      return "#000000";
  }
}

export const getSkillBackgroundImage = (skillId) => {
  switch (skillId) {
    case "1":
      return "/images/bg/character.png";
    case "2":
      return "/images/bg/collaboration.png";
    case "3":
      return "/images/bg/communication.png";
    case "4":
      return "/images/bg/creativity.png";
    case "5":
      return "/images/bg/critical_thinking.png";
    default:
      return "/images/bg/creativity_3.png";
  }
}

export const mergeSkillColorAndBackground = (data) => {
  return data.map((x) => {
    x.color = getSkillColor(x.skillId);
    x.textColor = getSkillTextColor(x.skillId);
    x.backgroundImage = getSkillBackgroundImage(x.skillId);
    return x;
  });
}