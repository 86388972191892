import {all} from "redux-saga/effects";
import {combineReducers} from "redux";
import * as auth from "./crud/authRedux"
import * as userRedux from "./reducers/userRedux";
import * as courseRedux from "./reducers/courseRedux";
// import {customersSlice} from "../app/modules/ECommerce/_redux/customers/customersSlice";
// import {productsSlice} from "../app/modules/ECommerce/_redux/products/productsSlice";
// import {remarksSlice} from "../app/modules/ECommerce/_redux/remarks/remarksSlice";
// import {specificationsSlice} from "../app/modules/ECommerce/_redux/specifications/specificationsSlice";

export const rootReducer = combineReducers({
  auth: auth.reducer,
  userRedux: userRedux.reducer,
  courseRedux: courseRedux.reducer
  // customers: customersSlice.reducer,
  // products: productsSlice.reducer,
  // remarks: remarksSlice.reducer,
  // specifications: specificationsSlice.reducer
});

export function* rootSaga() {
  yield all([
    auth.saga(),
    userRedux.saga(),
    courseRedux.saga()
  ]);
}
