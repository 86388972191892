import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import { withStyles } from "@material-ui/core";
import { Container, Paper, Divider, Grid, Backdrop, CircularProgress, Snackbar, Checkbox, FormControlLabel } from '@material-ui/core';
import messageType from "../../utils/messageType";
import globalStyles from "../../../styles/globalStyles";
import { getUserExperiences, addUserExperience, updateUserExperience, removeUserExperience } from "../../../redux/crud/users.crud";
import { searchExperienceOptions } from "../../../redux/crud/occupations.crud";
import { LinearProgress } from "@material-ui/core";

const styles = theme => ({
    learningSkill: globalStyles.learningSkill,
    paper: globalStyles.paper,
    paperNoBackground: globalStyles.paperNoBackground,
    learningSkillIcon: globalStyles.learningSkillIcon,
    pageTitle: globalStyles.pageTitle,
    checkBox: globalStyles.checkBox,
    customControl: globalStyles.textInput,
    itemLabel: {
        color: theme.palette.text.secondary,
        fontSize: 13,
        color: "#fff"
    },
    currentlyWorkingText: {
        marginTop: 15,
        color: "#fff"
    },
    divider: globalStyles.divider
})

const minYearValue = 1990;
const maxYearValue = new Date().getFullYear();
const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

class Experiences extends Component {
    constructor() {
        super();

        this.state = {
            dataList: [],
            selectedData: {
                id: -1,
                occupationId: -1,
                occupationName: "",
                title: "",
                companyName: "",
                startDate: new Date(),
                endDate: new Date(),
                description: "",
                location: ""
            },
            errors: {
                occupationName: false,
                title: false,
                companyName: false,
                startDate: false,
                endDate: false,
                description: false,
                location: false
            },
            currentlyWorkingChecked: false,
            editorTitle: "",
            occupationsList: [],
            selectedOccupation: {},
            isLoading: true,
            isSaving: false,
            isEditing: false,
            showDeleteButton: false,
            showMessage: false,
            message: null,
            messageType: null
        }

    }

    componentDidMount() {
        getUserExperiences()
            .then(({ data }) => {
                this.setState({
                    dataList: data,
                    isLoading: false
                })
            })
            .catch((err) => {
                console.log(err);
                this.setState({
                    message: err.message,
                    showMessage: true,
                    isLoading: false,
                    messageType: messageType.ERROR
                })
            });
    }

    renderExperiences = (dataList) => dataList.map(data => {
        const { classes } = this.props;

        let startDateInString = "-";
        let endDateInString = "-";
        if (data.startDate) {
            const startDate = new Date(data.startDate);
            startDateInString = startDate.getDate() + " " + months[startDate.getMonth()] + " " + startDate.getFullYear();
        }

        if (data.endDate && data.startDate) {
            const endDate = new Date(data.endDate);
            endDateInString = endDate.getDate() + " " + months[endDate.getMonth()] + " " + endDate.getFullYear();
        }
        else if (data.startDate) {
            endDateInString = "Sekarang";
        }

        let endDate = data.endDate;

        return (
            <div key={data.id} className="experiences-wrapper">
                <div className="experience-details">
                    <div className="experience-details-items">
                        <span className="experience-title">{data.title}</span>
                    </div>
                    <div className="experience-details-items">
                        <span className="label-item">{data.companyName}</span>
                    </div>
                    <div className="experience-details-items">
                        <span className="label-item-smaller">{startDateInString} - {endDateInString}</span>
                    </div>
                    <div className="experience-details-items">
                        <span className="label-item-smaller">{data.location}</span>
                    </div>
                </div>
                <Divider className={classes.divider} />
            </div>
        );
    });

    render() {
        const { classes, data } = this.props;
        const anchorOrigin = { vertical: "top", horizontal: "center" };

        return (
            <div>
                <React.Fragment>
                    <Container maxWidth="md">
                        <Paper className={classes.paper}>
                            <div className="paper-wrapper">
                                <div className="page-title">
                                    <h3 className={classes.pageTitle}>Experiences</h3>
                                </div>
                                <div className="top-right">

                                </div>
                                {this.renderExperiences(data)}
                            </div>
                        </Paper>
                    </Container>
                </React.Fragment>
            </div >
        );
    }
}

export default withStyles(styles, { withTheme: true })(Experiences)
