import React, { Component } from "react";
import { withStyles } from "@material-ui/core";
import { Paper, Grid, Divider, Snackbar, Container, Button } from "@material-ui/core";
import globalStyles from "../../../styles/globalStyles";
import messageType from "../../utils/messageType";
import { getUserBasicInformation, getUserProfilePicture } from "../../../redux/crud/users.crud";

const styles = theme => ({
    defaultBackgroundColor: globalStyles.defaultBackGroundColor,
    learningSkill: globalStyles.learningSkill,
    paper: globalStyles.paper,
    learningSkillIcon: globalStyles.learningSkillIcon,
    pageTitle: globalStyles.pageTitle,
    wrapper: {
        position: 'relative',
    },
    buttonProgress: {
        color: "#ffffff",
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: 5,
        marginLeft: -15
    },
    button: {
        border: "0px",
        textTransform: "capitalize",
        position: "relative",
        float: "left",
        fontSize: 12
    },
    divider: globalStyles.divider
})

class GeneralInfo extends Component {
    constructor() {
        super();

        this.state = {
            profilePicture: null,
            data: {
                fullName: "",
                companyName: "",
                location: ""
            }
        }
    }

    componentDidMount() {
        getUserBasicInformation()
            .then(({ data }) => {
                this.setState({
                    data: data
                })
            })
            .catch((err) => {
                console.log(err);
                this.setState({
                    message: err.message,
                    showMessage: true,
                    messageType: messageType.ERROR
                })
            });

        getUserProfilePicture()
            .then(({ data }) => {
                const urlCreator = window.URL || window.webkitURL;
                const imageURL = urlCreator.createObjectURL(data);
                this.setState({
                    profilePicture: imageURL
                });
            })
            .catch((err) => {
                console.log(err);
            });
    }

    render() {
        const { classes, profilePicture, data } = this.props;

        let fullName = "";
        if (data.firstName)
            fullName = data.firstName + " ";

        if (data.lastName)
            fullName += data.lastName;

        return (
            <Container maxWidth="xl">
                <Paper className={classes.paper}>
                    <Grid container spacing={2}>
                        <Grid container item xs={12} sm={12}>
                            <Grid container item xs={12} sm={12} xl={3} md={3} lg={3}>
                                <Grid>
                                    <div className="symbol symbol-150 symbol-xxl-150 mr-5 align-self-start align-self-xxl-center">
                                        <img src={profilePicture === null ? '/images/users/blank.png' : profilePicture} height="200px" loading="lazy" />
                                    </div>
                                </Grid>
                                <Grid>
                                    <h2 className="m-4">{fullName}</h2>
                                </Grid>
                            </Grid>
                            <Grid container item xs={12} sm={12} xl={9} md={9} lg={9}>
                                <Grid item xs={12} sm={12} xl={6} md={6} lg={6}>
                                    <span className="label-item-title">Perusahaan</span>
                                    <p className="label-item">{data.companyName}</p>
                                </Grid>
                                <Grid item xs={12} sm={12} xl={6} md={6} lg={6}>
                                    <span className="label-item-title">Sekolah</span>
                                    <p className="label-item">{data.educationSchoolName}</p>
                                </Grid>
                                <Grid item xs={12} sm={12} xl={6} md={6} lg={6}>
                                    <span className="label-item-title">Jurusan</span>
                                    <p className="label-item">{data.educationField}</p>
                                </Grid>
                                <Grid item xs={12} sm={12} xl={6} md={6} lg={6}>
                                    <span className="label-item-title">Lokasi</span>
                                    <p className="label-item">{data.location}</p>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Paper>
                <Divider className="m-4" />
                <Paper className={classes.paper}>
                    <div className="paper-wrapper">
                        <div className="page-title">
                            <h3 className={classes.pageTitle}>Tentang</h3>
                        </div>
                        <Grid container>
                            <p className="label-item">{data.about}</p>
                        </Grid>
                    </div>
                </Paper>
            </Container>)
    }
}

export default withStyles(styles, { withTheme: true })(GeneralInfo)
