import scAxios from "../scAxios";
import acsAxios from "../acsAxios";

export const LOGIN_URL = "/users/auth";
export const REGISTER_URL = "/users/signUp";
export const REQUEST_PASSWORD_URL = "/auth/forgot-password";

export const ME_URL = "/users/me";

export function login(email, password) {
  return scAxios.post(LOGIN_URL, { email, password });
}

export function register(email, fullname, username, password) {
  return scAxios.post(REGISTER_URL, { email, fullname, username, password });
}

export function requestPassword(email) {
  return scAxios.post(REQUEST_PASSWORD_URL, { email });
}

export function getUserByToken() {
  // Authorization head should be fulfilled in interceptor.
  return scAxios.get(ME_URL);
}

export function getAcsUserProfileByToken() {
  return acsAxios.get("/self/profile");
}

export function exchangeTokenToPresence(token) {
  return scAxios.get(`/users/auth/exchange/${token}`);
}

export function logout() {
  return scAxios.get('/users/auth/logout');
}